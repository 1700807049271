var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_vm.created ? _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), !_vm.editAll && _vm.$route.params.id ? _c('vx-btn', {
    attrs: {
      "width": "120",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$edit('page-schema', _vm.pageSchema._id);
      }
    }
  }, [_vm._v(" Edit Schema ")]) : _vm._e(), !_vm.editAll && _vm.$route.params.id ? _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.preview = true;
      }
    }
  }, [_vm._v(" Preview ")]) : _vm._e(), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, true);
      }
    }
  }, [_vm._v(" Save & close ")])], 1), !_vm.valid ? _c('v-alert', {
    attrs: {
      "dense": "",
      "type": "error"
    }
  }, [_vm._v("Please fill all required fields ")]) : _vm._e(), _vm.editAll ? _c('v-alert', {
    attrs: {
      "dense": "",
      "type": "info"
    }
  }, [_vm._v("Only selected components will be affected ")]) : _vm._e(), _c('v-expansion-panels', {
    staticClass: "v-sheet-outline",
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.panel,
      callback: function callback($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [!_vm.editAll ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('vx-btn', {
    staticClass: "flex-none handle",
    attrs: {
      "disabled": "",
      "icon": ""
    }
  }, [_c('vx-icon', [_vm._v("mdi-drag")])], 1), _vm._v(" Basic info ")], 1), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeName();
      }
    },
    model: {
      value: _vm.page.name,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "name", $$v);
      },
      expression: "page.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeSlug();
      }
    },
    model: {
      value: _vm.page.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "slug", $$v);
      },
      expression: "page.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "path",
      "label": "Public URL",
      "required": ""
    },
    model: {
      value: _vm.page.path,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "path", $$v);
      },
      expression: "page.path"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "280",
      "max": "280"
    },
    on: {
      "change": function change($event) {
        return _vm.setSeoDetails();
      }
    },
    model: {
      value: _vm.page.description,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "description", $$v);
      },
      expression: "page.description"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('tags', {
    model: {
      value: _vm.page.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "tags", $$v);
      },
      expression: "page.tags"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.userNames,
      "item-text": "displayName",
      "item-value": "_id",
      "name": "publishedBy",
      "label": "Publish by"
    },
    model: {
      value: _vm.page.publishedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "publishedBy", $$v);
      },
      expression: "page.publishedBy"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "date",
      "name": "publishedAt",
      "label": "Publish date"
    },
    model: {
      value: _vm.page.publishedAt,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "publishedAt", $$v);
      },
      expression: "page.publishedAt"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Publish"
    },
    model: {
      value: _vm.page.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "isActive", $$v);
      },
      expression: "page.isActive"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Sitemap?"
    },
    model: {
      value: _vm.page.sitemap,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "sitemap", $$v);
      },
      expression: "page.sitemap"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), !_vm.editAll ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('vx-btn', {
    staticClass: "flex-none handle",
    attrs: {
      "disabled": "",
      "icon": ""
    }
  }, [_c('vx-icon', [_vm._v("mdi-drag")])], 1), _vm._v(" Seo ")], 1), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "title",
      "label": "Title"
    },
    model: {
      value: _vm.page.seo.title,
      callback: function callback($$v) {
        _vm.$set(_vm.page.seo, "title", $$v);
      },
      expression: "page.seo.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "image",
      "label": "Image",
      "append-outer-icon": "mdi-folder-multiple-image"
    },
    on: {
      "click:append-outer": function clickAppendOuter($event) {
        return _vm.openFileDialog(_vm.page.seo, 'image');
      }
    },
    model: {
      value: _vm.page.seo.image,
      callback: function callback($$v) {
        _vm.$set(_vm.page.seo, "image", $$v);
      },
      expression: "page.seo.image"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "160",
      "max": "160"
    },
    model: {
      value: _vm.page.seo.description,
      callback: function callback($$v) {
        _vm.$set(_vm.page.seo, "description", $$v);
      },
      expression: "page.seo.description"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('vx-img', {
    attrs: {
      "rounded": "",
      "rectangle": "",
      "preview": "",
      "src": _vm.page.seo.image
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": 'text',
      "name": "alt",
      "label": "alt"
    },
    model: {
      value: _vm.page.seo.image_alt,
      callback: function callback($$v) {
        _vm.$set(_vm.page.seo, "image_alt", $$v);
      },
      expression: "page.seo.image_alt"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.canonical.pages,
      "item-text": "name",
      "item-value": "_id",
      "name": "canonical_page",
      "label": "Canonical page",
      "search-input": _vm.canonicalPageSearch
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.canonicalPageSearch = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.canonicalPageSearch = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "append-item",
      fn: function fn() {
        return [_vm.canonical.pages.length < _vm.canonical.count ? _c('div', {
          directives: [{
            name: "intersect",
            rawName: "v-intersect",
            value: _vm.loadMorePages,
            expression: "loadMorePages"
          }],
          staticClass: "pa-4 teal--text"
        }, [_vm.canonical.loading ? _c('span', [_vm._v("Loading more items ...")]) : _vm._e()]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1670334910),
    model: {
      value: _vm.page.seo.canonical_page,
      callback: function callback($$v) {
        _vm.$set(_vm.page.seo, "canonical_page", $$v);
      },
      expression: "page.seo.canonical_page"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), !_vm.editAll ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('vx-btn', {
    staticClass: "flex-none handle",
    attrs: {
      "disabled": "",
      "icon": ""
    }
  }, [_c('vx-icon', [_vm._v("mdi-drag")])], 1), _c('div', [_vm._v("Style")]), _c('vx-btn', {
    staticClass: "flex-none",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.page._style.push({});
      }
    }
  }, [_c('vx-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-expansion-panel-content', _vm._l(_vm.page._style, function (style, index) {
    return _c('v-row', {
      key: index,
      staticClass: "mx-0"
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "5"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "text",
        "name": "key",
        "label": "Key"
      },
      model: {
        value: style.key,
        callback: function callback($$v) {
          _vm.$set(style, "key", $$v);
        },
        expression: "style.key"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "5"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "text",
        "name": "value",
        "label": "Value"
      },
      model: {
        value: style.value,
        callback: function callback($$v) {
          _vm.$set(style, "value", $$v);
        },
        expression: "style.value"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "1"
      }
    }, [_c('vx-btn', {
      staticClass: "flex-none",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$remove(_vm.page._style, index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1);
  }), 1)], 1) : _vm._e(), _c('v-draggable', {
    staticClass: "w-100",
    attrs: {
      "list": _vm.pageSchema._components,
      "handle": ".handle",
      "ghost-class": "ghost"
    }
  }, [_vm._l(_vm.pageSchema._components, function (component, componentIndex) {
    return [component.isActive ? _c('v-expansion-panel', {
      key: componentIndex
    }, [_c('v-expansion-panel-header', [_vm.editAll ? _c('vx-input', {
      staticClass: "flex-none",
      attrs: {
        "type": "checkbox",
        "hide-details": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      },
      model: {
        value: component.checked,
        callback: function callback($$v) {
          _vm.$set(component, "checked", $$v);
        },
        expression: "component.checked"
      }
    }) : _vm._e(), _c('vx-btn', {
      staticClass: "flex-none handle",
      attrs: {
        "icon": ""
      }
    }, [_c('vx-icon', [_vm._v("mdi-drag")])], 1), _vm._v(" " + _vm._s(component.name || component.schemaId.name) + " "), _c('v-spacer'), _c('div', {
      staticClass: "flex-none d-flex"
    }, [_c('vx-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();

          _vm.$openBlankWindow(_vm.$toEdit('component-schema', component.schemaId._id));
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-pencil")])], 1), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": componentIndex === 0
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$moveUp(_vm.pageSchema._components, componentIndex);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-up")])], 1), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": componentIndex === _vm.pageSchema._components.length - 1
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$moveDown(_vm.pageSchema._components, componentIndex);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-down")])], 1), _c('v-switch', {
      attrs: {
        "hide-details": ""
      },
      model: {
        value: component._isActive,
        callback: function callback($$v) {
          _vm.$set(component, "_isActive", $$v);
        },
        expression: "component._isActive"
      }
    })], 1)], 1), _c('v-expansion-panel-content', [_vm.components[component.schemaId._id] ? [_c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": _vm.components[component.schemaId._id],
        "item-text": "name",
        "item-value": "_id",
        "name": "Component id",
        "label": "Pre filled Component",
        "append-outer-icon": "mdi-plus"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          _vm.$openBlankWindow(_vm.$toAdd('component', {
            id: component.schemaId._id
          }));
        }
      },
      model: {
        value: component.componentId,
        callback: function callback($$v) {
          _vm.$set(component, "componentId", $$v);
        },
        expression: "component.componentId"
      }
    }), _c('vx-divider', {
      staticClass: "mb-10",
      attrs: {
        "value": "Or"
      }
    })] : _vm._e(), _c('v-row', {
      staticClass: "mx-0"
    }, _vm._l(component.schemaId.fields, function (field, fieldIndex) {
      return _c('v-col', {
        key: fieldIndex,
        staticClass: "py-0",
        attrs: {
          "cols": "12",
          "sm": "12"
        }
      }, [field.type === 'collection' ? [_c('vx-input', {
        attrs: {
          "type": "autocomplete",
          "items": field.values,
          "item-text": "name",
          "item-value": "_id",
          "name": field.name,
          "multiple": field.multiple,
          "label": field.name,
          "required": !_vm.editAll && field.validation.required && component._isActive,
          "append-outer-icon": "mdi-plus"
        },
        on: {
          "change": function change($event) {
            _vm.key.list++;
          },
          "click:append-outer": function clickAppendOuter($event) {
            _vm.$openBlankWindow(_vm.$toAdd('collection', {
              id: field.schemaId
            }));
          }
        },
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }), field.multiple && Array.isArray(field.value) ? _c('vx-list', {
        key: fieldIndex + _vm.key.list,
        staticClass: "mb-7 rounded py-0",
        attrs: {
          "list-props": {
            dense: true,
            outlined: true
          },
          "draggable": "",
          "items": field.value,
          "item-title": function itemTitle(item) {
            return field._values[item];
          }
        },
        on: {
          "edit": function edit(item) {
            return _vm.$openBlankWindow(_vm.$toEdit('collection', item));
          }
        }
      }) : _vm._e()] : _vm.SPECIAL_FIELD_TYPES.includes(field.type) ? _c('vx-input', {
        attrs: {
          "type": "autocomplete",
          "items": field.values,
          "item-text": "name",
          "item-value": "_id",
          "name": field.name,
          "label": field.name,
          "multiple": field.multiple,
          "required": !_vm.editAll && field.validation.required && component._isActive
        },
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : field.type === 'file' ? [_c('vx-input', {
        attrs: {
          "type": "text",
          "name": field.name,
          "label": field.name,
          "required": !_vm.editAll && field.validation.required && component._isActive,
          "append-outer-icon": "mdi-folder-multiple-image"
        },
        on: {
          "click:append-outer": function clickAppendOuter($event) {
            return _vm.openFileDialog(field);
          }
        },
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }), _c('vx-img', {
        staticClass: "w-100 w-md-50 border-primary-1 rounded mb-7 mx-auto",
        attrs: {
          "rectangle": "",
          "preview": "",
          "placeholder": "",
          "src": field.value
        }
      }), component.schemaId.fields["".concat(field.slug, "_alt")] ? _c('vx-input', {
        attrs: {
          "type": 'text',
          "name": "alt",
          "label": "alt"
        },
        model: {
          value: component.schemaId.fields["".concat(field.slug, "_alt")],
          callback: function callback($$v) {
            _vm.$set(component.schemaId.fields, "".concat(field.slug, "_alt"), $$v);
          },
          expression: "component.schemaId.fields[`${field.slug}_alt`]"
        }
      }) : _c('vx-input', {
        attrs: {
          "type": 'text',
          "name": "alt",
          "label": "alt"
        },
        model: {
          value: field["".concat(field.slug, "_alt")],
          callback: function callback($$v) {
            _vm.$set(field, "".concat(field.slug, "_alt"), $$v);
          },
          expression: "field[`${field.slug}_alt`]"
        }
      })] : _c('vx-input', {
        attrs: {
          "type": field.type,
          "name": field.name,
          "label": field.name,
          "items": field.items,
          "multiple": field.multiple,
          "required": !_vm.editAll && field.validation.required && component._isActive
        },
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }), _vm.dev && field.type === 'wysiwyg' ? [_c('vx-input', {
        attrs: {
          "type": "textarea",
          "name": field.name,
          "label": field.name,
          "required": !_vm.editAll && field.validation.required && component._isActive
        },
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      })] : _vm._e()], 2);
    }), 1)], 2)], 1) : _vm._e()];
  })], 2)], 1)], 1) : _vm._e(), _c('vx-dialog', {
    attrs: {
      "actions": "",
      "scrollable": "",
      "width": "800",
      "title": "Add ".concat(_vm.field.name)
    },
    on: {
      "resolve": function resolve($event) {
        return _vm.onResolveCollection();
      }
    },
    model: {
      value: _vm.dialog.collection,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "collection", $$v);
      },
      expression: "dialog.collection"
    }
  }, [_c('div', [_c('vx-input', {
    attrs: {
      "type": "text",
      "label": "Search"
    },
    model: {
      value: _vm.field._search,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "_search", $$v);
      },
      expression: "field._search"
    }
  }), _c('v-list', {
    attrs: {
      "dense": "",
      "outlined": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "multiple": "",
      "color": "primary"
    },
    model: {
      value: _vm.field._value,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "_value", $$v);
      },
      expression: "field._value"
    }
  }, [_vm._l(_vm.field.values, function (item, index) {
    return [_c('v-list-item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.name.toLowerCase().includes((_vm.field._search || '').toLowerCase()),
        expression: "item.name.toLowerCase().includes((field._search || '').toLowerCase())"
      }],
      key: index,
      attrs: {
        "value": item._id
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.name))])], 1)], 1)];
  })], 2)], 1)], 1)]), _c('vx-dialog', {
    attrs: {
      "title": _vm.$getPreviewURL(_vm.page.path),
      "height": "90vh",
      "width": "90%"
    },
    model: {
      value: _vm.dialog.preview,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "preview", $$v);
      },
      expression: "dialog.preview"
    }
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "75vh"
    },
    attrs: {
      "src": _vm.$getPreviewURL(_vm.page.path)
    }
  })]), _c('AddFile', {
    on: {
      "resolve": _vm.onResolveFile,
      "reject": function reject($event) {
        _vm.dialog.file = false;
      }
    },
    model: {
      value: _vm.dialog.file,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "file", $$v);
      },
      expression: "dialog.file"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }