<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card v-if="created" outlined>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn
          v-if="!editAll && $route.params.id"
          width="120"
          outlined
          color="light"
          @click="$edit('page-schema', pageSchema._id)"
        >
          Edit Schema
        </vx-btn>
        <vx-btn
          v-if="!editAll && $route.params.id"
          width="100"
          outlined
          color="light"
          class="ml-2"
          @click="dialog.preview = true"
        >
          Preview
        </vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-alert v-if="!valid" dense type="error">Please fill all required fields </v-alert>
      <v-alert v-if="editAll" dense type="info">Only selected components will be affected </v-alert>

      <v-expansion-panels v-model="panel" accordion class="v-sheet-outline">
        <v-expansion-panel v-if="!editAll">
          <v-expansion-panel-header>
            <vx-btn disabled icon class="flex-none handle">
              <vx-icon>mdi-drag</vx-icon>
            </vx-btn>
            Basic info
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row class="mx-0">
              <v-col cols="12" sm="6" class="py-0">
                <vx-input v-model="page.name" type="text" name="name" label="Name" required @change="onChangeName()" />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input v-model="page.slug" type="text" name="slug" label="Slug" required @change="onChangeSlug()" />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input v-model="page.path" type="text" name="path" label="Public URL" required />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input
                  v-model="page.description"
                  type="textarea"
                  name="description"
                  label="Description"
                  counter="280"
                  max="280"
                  @change="setSeoDetails()"
                />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <tags v-model="page.tags" />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input
                  v-model="page.publishedBy"
                  type="autocomplete"
                  :items="userNames"
                  item-text="displayName"
                  item-value="_id"
                  name="publishedBy"
                  label="Publish by"
                />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input v-model="page.publishedAt" type="date" name="publishedAt" label="Publish date" />
              </v-col>

              <v-col cols="12" sm="3" class="py-0">
                <v-switch v-model="page.isActive" label="Publish" />
              </v-col>

              <v-col cols="12" sm="3" class="py-0">
                <v-switch v-model="page.sitemap" label="Sitemap?" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="!editAll">
          <v-expansion-panel-header>
            <vx-btn disabled icon class="flex-none handle">
              <vx-icon>mdi-drag</vx-icon>
            </vx-btn>
            Seo
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row class="mx-0">
              <v-col cols="12" sm="6" class="py-0">
                <vx-input v-model="page.seo.title" type="text" name="title" label="Title" />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input
                  v-model="page.seo.image"
                  type="text"
                  name="image"
                  label="Image"
                  append-outer-icon="mdi-folder-multiple-image"
                  @click:append-outer="openFileDialog(page.seo, 'image')"
                />
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input
                  v-model="page.seo.description"
                  type="textarea"
                  name="description"
                  label="Description"
                  counter="160"
                  max="160"
                />
              </v-col>

              <v-col cols="12" sm="6" class="pt-0">
                <v-row class="ma-0">
                  <v-col cols="12" lg="6">
                    <vx-img rounded rectangle preview :src="page.seo.image" />
                  </v-col>
                  <v-col cols="12" lg="6">
                    <vx-input v-model="page.seo.image_alt" :type="'text'" name="alt" label="alt" />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <vx-input
                  v-model="page.seo.canonical_page"
                  type="autocomplete"
                  :items="canonical.pages"
                  item-text="name"
                  item-value="_id"
                  name="canonical_page"
                  label="Canonical page"
                  :search-input.sync="canonicalPageSearch"
                >
                  <template v-slot:append-item>
                    <div
                      v-if="canonical.pages.length < canonical.count"
                      v-intersect="loadMorePages"
                      class="pa-4 teal--text"
                    >
                      <span v-if="canonical.loading">Loading more items ...</span>
                    </div>
                  </template>
                </vx-input>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="!editAll">
          <v-expansion-panel-header>
            <vx-btn disabled icon class="flex-none handle">
              <vx-icon>mdi-drag</vx-icon>
            </vx-btn>

            <div>Style</div>

            <vx-btn icon class="flex-none" @click.stop="page._style.push({})">
              <vx-icon>mdi-plus</vx-icon>
            </vx-btn>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row v-for="(style, index) in page._style" :key="index" class="mx-0">
              <v-col cols="12" sm="5" class="py-0">
                <vx-input v-model="style.key" type="text" name="key" label="Key" />
              </v-col>

              <v-col cols="12" sm="5" class="py-0">
                <vx-input v-model="style.value" type="text" name="value" label="Value" />
              </v-col>

              <v-col cols="12" sm="1" class="py-0">
                <vx-btn icon class="flex-none" @click.stop="$remove(page._style, index)">
                  <vx-icon>mdi-delete</vx-icon>
                </vx-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-draggable class="w-100" :list="pageSchema._components" handle=".handle" ghost-class="ghost">
          <template v-for="(component, componentIndex) in pageSchema._components">
            <v-expansion-panel v-if="component.isActive" :key="componentIndex">
              <v-expansion-panel-header>
                <vx-input
                  v-if="editAll"
                  v-model="component.checked"
                  class="flex-none"
                  type="checkbox"
                  hide-details
                  @click.stop
                />

                <vx-btn icon class="flex-none handle">
                  <vx-icon>mdi-drag</vx-icon>
                </vx-btn>

                {{ component.name || component.schemaId.name }}

                <v-spacer />

                <div class="flex-none d-flex">
                  <vx-btn icon @click.stop="$openBlankWindow($toEdit('component-schema', component.schemaId._id))">
                    <vx-icon>mdi-pencil</vx-icon>
                  </vx-btn>

                  <vx-btn
                    icon
                    :disabled="componentIndex === 0"
                    @click.stop="$moveUp(pageSchema._components, componentIndex)"
                  >
                    <vx-icon>mdi-arrow-up</vx-icon>
                  </vx-btn>

                  <vx-btn
                    icon
                    :disabled="componentIndex === pageSchema._components.length - 1"
                    @click.stop="$moveDown(pageSchema._components, componentIndex)"
                  >
                    <vx-icon>mdi-arrow-down</vx-icon>
                  </vx-btn>

                  <v-switch v-model="component._isActive" hide-details />
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <template v-if="components[component.schemaId._id]">
                  <vx-input
                    v-model="component.componentId"
                    type="autocomplete"
                    :items="components[component.schemaId._id]"
                    item-text="name"
                    item-value="_id"
                    name="Component id"
                    label="Pre filled Component"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="$openBlankWindow($toAdd('component', { id: component.schemaId._id }))"
                  />

                  <vx-divider class="mb-10" value="Or" />
                </template>

                <v-row class="mx-0">
                  <v-col
                    v-for="(field, fieldIndex) in component.schemaId.fields"
                    :key="fieldIndex"
                    cols="12"
                    sm="12"
                    class="py-0"
                  >
                    <template v-if="field.type === 'collection'">
                      <vx-input
                        v-model="field.value"
                        type="autocomplete"
                        :items="field.values"
                        item-text="name"
                        item-value="_id"
                        :name="field.name"
                        :multiple="field.multiple"
                        :label="field.name"
                        :required="!editAll && field.validation.required && component._isActive"
                        append-outer-icon="mdi-plus"
                        @change="key.list++"
                        @click:append-outer="$openBlankWindow($toAdd('collection', { id: field.schemaId }))"
                      />

                      <vx-list
                        v-if="field.multiple && Array.isArray(field.value)"
                        :key="fieldIndex + key.list"
                        class="mb-7 rounded py-0"
                        :list-props="{ dense: true, outlined: true }"
                        draggable
                        :items="field.value"
                        :item-title="item => field._values[item]"
                        @edit="item => $openBlankWindow($toEdit('collection', item))"
                      />
                    </template>

                    <vx-input
                      v-else-if="SPECIAL_FIELD_TYPES.includes(field.type)"
                      v-model="field.value"
                      type="autocomplete"
                      :items="field.values"
                      item-text="name"
                      item-value="_id"
                      :name="field.name"
                      :label="field.name"
                      :multiple="field.multiple"
                      :required="!editAll && field.validation.required && component._isActive"
                    />

                    <template v-else-if="field.type === 'file'">
                      <vx-input
                        v-model="field.value"
                        type="text"
                        :name="field.name"
                        :label="field.name"
                        :required="!editAll && field.validation.required && component._isActive"
                        append-outer-icon="mdi-folder-multiple-image"
                        @click:append-outer="openFileDialog(field)"
                      />

                      <vx-img
                        rectangle
                        preview
                        placeholder
                        class="w-100 w-md-50 border-primary-1 rounded mb-7 mx-auto"
                        :src="field.value"
                      />

                      <vx-input
                        v-if="component.schemaId.fields[`${field.slug}_alt`]"
                        v-model="component.schemaId.fields[`${field.slug}_alt`]"
                        :type="'text'"
                        name="alt"
                        label="alt"
                      />

                      <vx-input v-else v-model="field[`${field.slug}_alt`]" :type="'text'" name="alt" label="alt" />
                    </template>

                    <vx-input
                      v-else
                      v-model="field.value"
                      :type="field.type"
                      :name="field.name"
                      :label="field.name"
                      :items="field.items"
                      :multiple="field.multiple"
                      :required="!editAll && field.validation.required && component._isActive"
                    />

                    <template v-if="dev && field.type === 'wysiwyg'">
                      <vx-input
                        v-model="field.value"
                        type="textarea"
                        :name="field.name"
                        :label="field.name"
                        :required="!editAll && field.validation.required && component._isActive"
                      />
                    </template>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-draggable>
      </v-expansion-panels>
    </v-card>

    <vx-dialog
      v-model="dialog.collection"
      actions
      scrollable
      width="800"
      :title="`Add ${field.name}`"
      @resolve="onResolveCollection()"
    >
      <div>
        <vx-input v-model="field._search" type="text" label="Search" />

        <v-list dense outlined>
          <v-list-item-group v-model="field._value" multiple color="primary">
            <template v-for="(item, index) in field.values">
              <v-list-item
                v-show="item.name.toLowerCase().includes((field._search || '').toLowerCase())"
                :key="index"
                :value="item._id"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </vx-dialog>

    <vx-dialog v-model="dialog.preview" :title="$getPreviewURL(page.path)" height="90vh" width="90%">
      <iframe :src="$getPreviewURL(page.path)" style="width: 100%; height: 75vh"> </iframe>
    </vx-dialog>

    <AddFile v-model="dialog.file" @resolve="onResolveFile" @reject="dialog.file = false" />
  </v-form>
</template>

<script>
import _ from 'lodash';
import vDraggable from 'vuedraggable';

import { FIELD_TYPES } from '@tutti/constants';
import { Page, PageSchema } from '@tutti/models';
import { PageService, PageSchemaService, UserService, ComponentService } from '@tutti/services';

import AddFile from '@admin/dialogs/add-file';
import Tags from '@admin/components/tags';

export default {
  name: 'PageEdit',

  components: { AddFile, vDraggable, Tags },

  props: {
    edit: { type: Boolean, default: false },
    editAll: { type: Boolean, default: false },
  },

  data() {
    return {
      key: {
        field: 0,
        list: 0,
      },
      valid: true,
      SPECIAL_FIELD_TYPES: FIELD_TYPES.filter(field => field.special).map(field => field.value),
      panel: parseInt(this.$route.params.tab),
      loading: false,
      created: false,
      page: new Page(),
      pageSchema: {},
      components: {},
      dialog: {
        file: false,
        preview: false,
        collection: false,
      },
      field: {
        value: [],
      },
      fieldKey: '',
      userNames: [],
      canonical: {
        pages: [],
        page: 1,
        limit: 24,
        loading: false,
        count: 0,
      },
      canonicalPageSearch: null,
    };
  },
  watch: {
    async canonicalPageSearch(val) {
      await this.getPageList(val);
    },
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getPage();
    await this.getPageSchema();
    this.created = true;

    this.getUserNames();
    this.getComponents();

    this.getPageList();

    this.setSeoDetails();
  },

  methods: {
    openFileDialog(field, fieldKey = 'value') {
      this.field = field;
      this.fieldKey = fieldKey;
      this.dialog.file = true;
    },

    onResolveFile(file) {
      this.field[this.fieldKey] = file.url;
      this.dialog.file = false;
    },

    rearrangeValues(field) {
      this.field = field;
      this.field._value = field.value;
      this.dialog.collection = true;
    },

    onResolveCollection() {
      this.field.value = this.field._value;
    },

    onChangeName() {
      this.page.setSlug(this.page.slug || this.page.name);
      this.page.setPath(this.pageSchema.path);
      this.setSeoDetails();
    },

    onChangeSlug() {
      this.page.setSlug(this.page.slug);
      this.page.setPath(this.pageSchema.path);
    },

    setSeoDetails() {
      if (!this.page.seo.title) {
        this.page.seo.title = this.page.name;
      }
      if (!this.page.seo.description) {
        this.page.seo.description = this.page.description;
      }
    },

    async getUserNames() {
      const response = await UserService.getNames({ isAdmin: true });
      if (response) {
        this.userNames = response.data;
      }
    },

    async getComponents() {
      const response = await ComponentService.getAll({});
      if (response) {
        this.components = _.groupBy(response.data.data, 'schemaId._id');
      }
    },

    async getPage() {
      if (this.$route.params.id) {
        const response = await PageService.getByIdAdmin(this.$route.params.id);

        if (response) {
          this.page = new Page(response.data);
          this.setSeoImageAltTag();
          if (!this.page.publishedBy) {
            this.page.publishedBy = this.$user._id;
          }
        }
      }
    },

    async getPageSchema() {
      if (this.$route.query.id || this.page.schemaId) {
        const response = await PageSchemaService.getByIdAdmin(this.$route.query.id || this.page.schemaId);

        if (response) {
          this.pageSchema = new PageSchema(response.data);
          this.pageSchema.setComponents(this.page.components, { isEdit: this.edit });
          if (this.editAll) {
            this.$setTitle(`Edit all pages in ${this.pageSchema.name}`);
          } else if (this.edit) {
            this.$setTitle(`Edit ${this.page.name} in ${this.pageSchema.name}`);
          } else {
            this.$setTitle(`Add page in ${this.pageSchema.name}`);
          }
        }
      } else {
        this.$previous();
      }
    },

    async save(previous) {
      this.loading = true;
      this.page.schemaId = this.pageSchema._id;
      this.page.setComponents(this.pageSchema._components);

      let promise;
      if (this.editAll) {
        promise = PageService.editAll(this.page.get());
      } else {
        promise = PageService.save(this.page.get());
      }

      const response = await promise;
      if (response) {
        if (previous) {
          this.$previous();
        } else if (response.data?._id) {
          this.$replace(this.$toEdit('page', response.data?._id));
        }
      }
      this.loading = false;
    },

    async getPageList(search = '') {
      this.canonical.loading = true;
      let params = {
        limit: this.canonical.limit,
        page: this.canonical.page,
      };
      if (search) {
        params.search = { text: search };
      }
      const response = await PageService.getAll(params);
      if (response) {
        this.canonical.count = response.data.count;
        this.canonical.pages = [...this.canonical.pages, ...response.data.data];
      }
      this.canonical.loading = false;
    },
    loadMorePages() {
      this.canonical.page += 1;
      this.getPageList();
    },
    setSeoImageAltTag() {
      if (!this.page.seo.image_alt && this.page.seo.image) {
        let url = new URL(this.page.seo.image);
        const params = new URLSearchParams(url.search);
        this.page.seo.image_alt = params.get('alt') || null;
      }
    },
  },
};
</script>
